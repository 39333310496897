<script>
import {stringCompare} from "@utils/stringUtil"
export default {
	methods : {
		tableSortSKU(a,b) {
			const val = stringCompare(a.part.sku,b.part.sku);
			return (val == 0) ? a.part_index - b.part_index : val;
		} ,
		tableSortName(a,b) {
			const val = stringCompare(a.part.name,b.part.name);
			return (val == 0) ? this.tableSortSKU(a,b) : val;
		} ,
		tableSortPartType(a,b) {
			const val = stringCompare(a.part.part_type,b.part.part_type);
			return (val == 0) ? this.tableSortSKU(a,b) : val;
		} ,
		tableSortDronePartType(a,b) {
			const sortA = a.type == 'initial' ? 0 : 1
			const sortB = a.type == 'optional' ? 0 : 1
			if (sortA > sortB) {
				return 1;
			} else if (sortA < sortB) {
				return -1;
			} else {
				return this.tableSortSKU(a,b);
			}
		} ,

	}
}
</script>
