<i18n locale="th" lang="yaml" >
page.title : "เพิ่ม Drone ในระบบ"
page.description : "ทำการเพิ่ม Drone ที่เพิ่งผลิตใหม่ ในระบบ"

step1.title : "กรอกข้อมูล"
step1.description : "กรอกข้อมูลเฉพาะของ Drone"
step2.title : "ระบุอะไหล่ที่ใช้"
step2.description : "ระบุเลข Serial No ของอะไหล่ที่ใช้"
step3.title : "ตรวจสอบ"
step3.description : "ตรวจสอบข้อมูลก่อนยืนยัน"
step4.title : "สำเร็จ"
step4.description : "เพิ่ม Drone ในระบบเรียบร้อย"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>
		<a-card :bordered="true">
			<a-steps class="drone-steps"
				:current="currentStep"
				label-placement="vertical">
				<a-step :title="$t('step1.title')" :description="$t('step1.description')"/>
				<a-step :title="$t('step2.title')" :description="$t('step2.description')" />
				<a-step :title="$t('step3.title')" :description="$t('step3.description')" />
				<a-step :title="$t('step4.title')" :description="$t('step4.description')" />
			</a-steps>
		</a-card>
			<div class="drone-steps-content">
				<keep-alive>
					<DroneCreateStep1 v-if="currentStep === 0"
						@submit="step1Submit"/>
					<DroneCreateStep2 v-if="currentStep === 1"
						:model="droneModel"
						:lot="droneLot"
						:firmware="firmware"
						@submit="step2Submit"
						@back="step2Back"/>
				</keep-alive>
				<DroneCreateStep3 v-if="currentStep === 2"
					:model="droneModel"
					:lot="droneLot"
					:firmware="firmware"
					:drone-parts="droneParts"
					:serial-no-datas="serialNoDatas"
					:create-form-data="createFormData"
					:has-warning="hasWarning"
					@submit="step3Submit"
					@back="step3Back"/>

				<DroneCreateStep4 v-if="currentStep === 3"
					:model="droneModel"
					:lot="droneLot"
					:firmware="firmware"
					:drone="drone"/>
			</div>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import DroneCreateStep1 from "@components/drone/DroneCreateStep1.vue"
import DroneCreateStep2 from "@components/drone/DroneCreateStep2.vue"
import DroneCreateStep3 from "@components/drone/DroneCreateStep3.vue"
import DroneCreateStep4 from "@components/drone/DroneCreateStep4.vue"
import {Steps} from "ant-design-vue"

export default {
	page() {
		return {
			title : this.$t('page.title')
		}
	} ,
	components : {
		DroneCreateStep1 , DroneCreateStep2 ,
		DroneCreateStep3 , DroneCreateStep4 ,
		"a-steps" : Steps,
		"a-step" : Steps.Step ,
	},
	mixins: [PageMixin] ,
	data() {
		return {
			currentStep : 0,
			// Result Step 1
			droneModel : undefined,
			droneLot : undefined	,
			firmware : undefined ,
			createFormData : {} ,
			// Result Step 2
			droneParts : [] ,
			serialNoDatas : [] ,
			hasWarning : false,
			// Result Step 3 ,
			drone : undefined
		}
	} ,
	methods : {
		step1Submit(data) {
			this.createFormData = data.formData;
			this.droneModel = data.droneModel;
			this.droneLot = data.droneLot;
			this.firmware = data.firmware
			this.currentStep = 1;
		} ,
		step2Submit(data) {
			this.droneParts = data.droneParts
			this.serialNoDatas = data.serialNoDatas
			this.hasWarning = data.hasWarning
			this.currentStep = 2;
		} ,
		step2Back() {
			this.currentStep = 0;
		} ,
		step3Submit(data) {
			this.currentStep = 3;
			this.drone = data.drone;
		} ,
		step3Back() {
			this.currentStep = 1;
		} ,

	}
}
</script>

<style lang="less" scoped>
.drone-steps::v-deep {
	.ant-steps-item-title {
		font-size : 14px;
	}
	.ant-steps-item-description {
		font-size : 12px;
	}

	body[data-device=mobile] & {
		.ant-steps-item-title {
			font-size : 12px;
		}
		.ant-steps-item-description {
			display:none;
		}
	}
}
.drone-steps-content::v-deep {
	margin: 24px auto 0;
	padding : 16px 32px;
	background : @white;

	.step-title {
		color : @primary-color;
		font-family: @font-family-title;
		font-size : 1.5em;
		font-weight: 600;
	}
	.step-description {
		color : @text-muted;
		font-size : 1em;
		margin-bottom : 32px;
	}
	body[data-device=mobile] & {
		padding : 16px;
	}
}
</style>
