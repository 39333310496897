<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone_model.field.drone_type : "การใช้งาน"
drone_model.field.status.draft.tooltip : "กำลังใส่ข้อมูลอยู่ ยังไม่สามารถเพิ่ม Lot หรือ ระบุ Drone ได้"

drone_lot.title : "ข้อมูล Lot"
drone_lot.not_specify : "ยังไม่ระบุ Lot"
drone_lot.field.manufacture_date : "วันผลิต"
</i18n>

<template>
	<a-card
		:loading="loading"
		:hoverable="clickable"
		:bordered="bordered"
		:class="cardCssClasses"
		@click="handleClick">
		<div class="mycard-content">
			<div class="mycard-header">
				<!-- TODO Target -->
				<my-router-link name="drone_model/view" :param-value="model.id"  auth-action="read" auth-module="droneModel" @click.stop>
					<ImageLoader
						class="mycard-image"
						:src="model.image_url"
						border-type="round">
						<DroneIcon class="anticon" />
					</ImageLoader>
				</my-router-link>

			</div>
			<ul class="mycard-details">
				<li>
					<span class="title text-bold">{{model.name}}</span> <span>({{model.display_name}})</span>
				</li>
				<li>
					{{$t('drone_model.field.drone_type')}} : {{$tenum('drone_type',model.drone_type)}}
				</li>
				<li>
					{{$t('drone_model.field.release_date')}} : {{model.release_date | date}}
				</li>
				<li class="mycard-tags">
					<MyTooltip
						v-show="model.status === 'draft'"
						:title="$t('drone_model.field.status.draft.tooltip')">
						<a-tag  class="mytag-primary">Draft</a-tag>
					</MyTooltip>
				</li>
			</ul>

			<ul class="mycard-details lot-detail-pane">
				<li>
					<span class="title text-bold text-center">{{$t('drone_lot.title')}}</span>
				</li>
				<li>
					{{lot.lot_name|emptyCheck($t('drone_lot.not_specify'))}}
				</li>
				<li v-show="lot.id">
					{{$t('drone_lot.field.manufacture_date')}} : <br/>
					{{lot.manufacture_date | date}}
				</li>
			</ul>
		</div>
		<div  class="mycard-invalid-status">
			<span v-if="model.status === 'inactive'">
				<a-icon  type="close-circle" />
				{{$tenum('drone_model.status','inactive')}}
			</span>
		</div>
	</a-card>
</template>

<script>
import MyTooltip from "@components/common/MyTooltip.vue"
import DroneIcon from "@components/icon/DroneIcon.vue"
import ImageLoader from "@components/common/ImageLoader.vue"
import {Tag} from "ant-design-vue"
export default {
	components : {
		ImageLoader, MyTooltip,
		"a-tag" : Tag,DroneIcon
	} ,
	props : {
		loading : {
			type: Boolean,
			default : false,
		} ,
		bordered : {
			type: Boolean ,
			default : false,
		} ,
		size : {
			type: String,
			default: "",
		} ,
		model : {
			type : null,
			default : () => []
		} ,
		lot : {
			type : null,
			default : () => []
		} ,
		linkTarget : {
			type : String,
			default : "_self"
		} ,
		clickable :{
			type : Boolean,
			default : false,
		}
	},

	computed : {
		myLink() {
			if (this.lot.id) {
				return this.$router.resolve({
					name : "drone_lot/view" ,
					params : {id : this.lot.id}
				}).href
			} else {
				return this.modelLink
			}
		} ,
		modelLink() {
			if (this.model.id) {
				return this.$router.resolve({
					name : "drone_model/lots" ,
					params : {id : this.model.id}
				}).href
			} else {
				return "#"
			}
		} ,
		cardCssClasses() {
			return [
				"drone-lot-lite-card","mycard","mycard-columns","mycard-with-details",
				{
					"mycard-sm" : this.size == 'small' ,
					"mycard-inactive" : this.model.status === 'inactive' ,
				}
			]
		} ,
	} ,
	methods : {
		handleClick(event) {
			if (!this.clickable)
				return;
			window.open(this.myLink,this.linkTarget)
		} ,
	}
}
</script>
<style lang="less" scoped>
	.drone-lot-lite-card::v-deep {
		max-width : 500px;

		.ant-card-body {
			padding : 16px 16px;
		}
		body[data-device=mobile] & {
			.mycard-content {
				flex-wrap : wrap;
			}
			.lot-detail-pane {
				margin-left : 0 !important;
				padding-left : 8px !important;
				border-left : none;
				border-top: 2px dashed @border-color-base;
				margin-top: 12px;
				padding-top: 8px;
			}
		}
	}
	.lot-detail-pane {
		border-left : 2px dashed @border-color-base;
		margin-left : 8px !important;
		padding-left : 12px  !important;
		width : 150px;
	}
</style>
