<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone.create.step1.title : "กรอกข้อมูลของ Drone ตัวนี้"
drone.create.step1.description : "ระบุข้อมูลเฉพาะของ Drone ตัวนี้ เพื่อใช้ในการอ้างอิงในระบบ"

drone.field.drone_model_id.help : "เลือกรุ่นของ Drone ตัวนี้"
drone.field.drone_model_id.placeholder : "เลือกรุ่น"

drone.field.drone_lot_id.help : "เลือก Lot ของ Drone ตัวนี้"
drone.field.drone_lot_id.placeholder : "เลือก Lot"

drone.field.serial_no.help : "S/N ของ Drone ตัวนี้ เช่น 200224070"
drone.field.serial_no.placeholder : "S/N เช่น 200224070"

drone.field.pixhawk_serial_no.help : "Pixhawk S/N ของ Drone"

drone.field.name.help : "ชื่อย่อสำหรับอ้างอิง Drone ตัวนี้"
drone.field.name.placeholder : "เช่น 070"

drone.field.latest_flight_time_sec.help : "ค่าตั้งต้น Flight Time ของ Drone ตัวนี้"
</i18n>

<template>
<div class="page-padding">
	<div class="step-title">
		{{$t('drone.create.step1.title')}}
	</div>
	<div class="step-description">
		{{$t('drone.create.step1.description')}}
	</div>
	<a-form
		layout="horizontal"
		:label-col="labelCol"
		:wrapper-col="wrapperCol"
		:form="formObj"
		@submit.prevent="handleSubmit">
		<a-form-item :label="$t('drone.field.drone_model_id')">
			<a-select v-decorator="[
					'drone_model_id' ,
					{
						rules: [
							{ required: true,message: $tt('validate.required','drone.field.drone_model_id') },
						],
					},
				]"
				:loading="droneModelsLoading"
				:placeholder="$t('drone.field.drone_model_id.placeholder')"
				:get-popup-container="getPopupContainer"
				@change="handleModelChange">
				<a-select-option v-for="model of droneModels" :key="model.id"
					:value="model.id">
					{{model.name}}
				</a-select-option>
			</a-select>
		</a-form-item>
		<a-form-item :label="$t('drone.field.drone_lot_id')">
			<a-select v-decorator="[
					'drone_lot_id' ,
					{
						rules: [
							{ required: true,message: $tt('validate.required','drone.field.drone_lot_id') },
						],
					},
				]"
				:disabled="droneLotsDisable"
				:loading="droneLotsLoading"
				:placeholder="$t('drone.field.drone_lot_id.placeholder')"
				:get-popup-container="getPopupContainer"
				@change="handleLotChange">
				<a-select-option v-for="lot of droneLots" :key="lot.id"
					:value="lot.id">
					{{lot.lot_name}}
				</a-select-option>
			</a-select>
		</a-form-item>

		<a-form-item v-show="selectedModel" v-bind="wrapperOnlyLayout">
			<DroneLotLiteCard
				:bordered="true"
				link-target="_blank"
				:clickable="true"
				:model="selectedModel"
				:lot="selectedLot"/>
		</a-form-item>

		<a-form-item :label="$t('drone.field.serial_no')"
			:extra="$t('drone.field.serial_no.help')">
			<a-input
				v-decorator="[
					'serial_no' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','drone.field.serial_no') },
					] },
				]"

				:placeholder="$t('drone.field.serial_no.placeholder')" />
		</a-form-item>
		<a-form-item :label="$t('drone.field.pixhawk_serial_no')"
			:extra="$t('drone.field.pixhawk_serial_no.help')">
			<a-input
				v-decorator="[
					'pixhawk_serial_no' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','drone.field.pixhawk_serial_no') },
					] },
				]"/>
		</a-form-item>
		<a-form-item :label="$t('drone.field.name')"
			:extra="$t('drone.field.name.help')" >
			<a-input
				v-decorator="[
					'name' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','drone.field.name') },
					] },
				]"
				class="drone-input-name"
				:addon-before="namePrefix"
				:placeholder="$t('drone.field.name.placeholder')" />
		</a-form-item>

		<a-form-item :label="$t('drone.field.firmware')">
			<FirmwareSelect
				v-decorator="[
					'firmware_id' ,
					{
						rules: [
							{ required: true,message: $tt('validate.required','drone.field.firmware') },
						],
					},
				]"
				:firmware-set-id="firmwareSetId"
				:disabled="!selectedModel"/>
		</a-form-item>
		<a-form-item :label="$t('drone.field.latest_flight_time_sec')"
			:extra="$t('drone.field.latest_flight_time_sec.help')">
			<FlightTimeInput
				v-decorator="[
					'latest_flight_time_sec' ,
					{
						rules: [
						{ required: true ,message: $tt('validate.required','drone.field.latest_flight_time_sec') },
					] , initialValue: 0 },
				]"/>
		</a-form-item>

		<div class="mysteps-actions">
			<a-button
				:loading="formLoading"
				type="info"
				html-type="submit"
				size="large">
				 {{$t('common.next')}} <a-icon type="right" />
			</a-button>
		</div>

	</a-form>
</div>
</template>

<script>
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import DroneLotLiteCard from "@components/drone/DroneLotLiteCard.vue"
import FlightTimeInput from "@components/input/FlightTimeInput.vue"
import FirmwareSelect from "@components/firmware/FirmwareSelect.vue"
import { Select } from 'ant-design-vue'
import { isVarEmpty } from '@utils/stringUtil'
import ApiError from '@utils/errors/ApiError'
import {mapGetters} from "vuex"
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
	 FlightTimeInput , DroneLotLiteCard,FirmwareSelect ,
		"a-select" : Select, "a-select-option" : Select.Option
	} ,
	mixins : [HasAntdFormMixin,PageMixin,PopupMixin] ,
	data() {
		return {
			droneModels : [] ,
			droneModelsLoading : false ,
			selectedModel : undefined,

			droneLots : [] ,
			droneLotsLoading : false,
			droneLotsDisable : true,
			selectedLot : undefined,

			formLoading : false,
		}
	} ,
	computed : {
		...mapGetters('drone',['getFirmwareById']) ,
		namePrefix() {
			return this.selectedModel ? this.selectedModel.drone_name_prefix : "XXX"
		} ,
		firmwareSetId() {
			return this.selectedModel ? this.selectedModel.firmware_set_id : -1
		}
	} ,
	created() {
		this.fetchModels(true)
	} ,
	methods : {
		fetchModels(create=false) {
			this.droneModelsLoading = true
			axios.get("/api/drone-models/active").then((response) => {
				this.droneModels = response.data.data.drone_models
				if (create && this.$route.query.model) {
					const initModelId = parseInt(this.$route.query.model)
					if (this.droneModels.findIndex((model)=>model.id == initModelId) >= 0) {
						this.$nextTick(() => {
							this.formObj.setFieldsValue({drone_model_id : initModelId})
							this.handleModelChange(initModelId,create)
						})
					}
				}
			}).catch((error) => {
				this.fetchError(error)
			}).finally(()=>{
				this.droneModelsLoading = false
			})
		} ,
		fetchLots(create=false) {
			this.droneLots = []
			this.formObj.setFieldsValue({'drone_lot_id' : undefined})
			this.handleLotChange(undefined)
			if (!this.selectedModel) {
				this.droneLotsDisable = true
			} else {
				this.droneLotsDisable = true
				this.droneLotsLoading = true
				axios.get("/api/drone-models/"+this.selectedModel.id+"/lots").then((response)=>{
					this.droneLotsDisable = false
					this.droneLots = response.data.data.drone_lots
					if (create && this.$route.query.lot) {
						const initLotId = parseInt(this.$route.query.lot)
						if (this.droneLots.findIndex((lot)=>lot.id == initLotId) >= 0) {
							this.$nextTick(() => {
								this.formObj.setFieldsValue({drone_lot_id : initLotId})
								this.handleLotChange(initLotId)
							})
						}
					}
				}).catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this,error))
				}).finally(() => {
					this.droneLotsLoading = false
				})
			}
		} ,

		handleModelChange(value,create=false) {
			if (!value || isVarEmpty(this.droneModels)) {
				this.selectedModel = undefined
			} else {
				this.selectedModel = this.droneModels.find((model)=> model.id == value);
			}
			this.fetchLots(create)
			this.formObj.setFieldsValue({'firmware_id' : undefined})
		} ,
		handleLotChange(value) {
			if (!value || isVarEmpty(this.droneLots)) {
				this.selectedLot = undefined
			} else {
				this.selectedLot = this.droneLots.find((lot)=> lot.id == value);
			}
		} ,

		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				if (!err) {
					const formData = {
						...values ,
						name : this.namePrefix + values.name ,
						};
					this.formLoading = true
					const selectedFirmware = this.getFirmwareById(formData.firmware_id)
					axios.post("/api/drones/create-validate",formData).then((response)=> {
						this.$emit('submit',{
							formData ,
							"droneModel" : {... this.selectedModel} ,
							"droneLot" : { ... this.selectedLot} ,
							"firmware" : selectedFirmware
						})
					}).catch((error) => {
						this.formSubmitErrors(error)
					}).finally(()=>{
						this.formLoading = false
					})
				} else {
					this.displayValidateErrorMessage()
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.drone-input-name::v-deep .ant-input-group{
	max-width : 350px;
}
</style>
