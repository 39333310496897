var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-padding"},[_c('div',{staticClass:"step-title"},[_vm._v("\r\n\t\t"+_vm._s(_vm.$t('drone.create.step1.title'))+"\r\n\t")]),_vm._v(" "),_c('div',{staticClass:"step-description"},[_vm._v("\r\n\t\t"+_vm._s(_vm.$t('drone.create.step1.description'))+"\r\n\t")]),_vm._v(" "),_c('a-form',{attrs:{"layout":"horizontal","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.drone_model_id')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'drone_model_id' ,
					{
						rules: [
							{ required: true,message: _vm.$tt('validate.required','drone.field.drone_model_id') },
						],
					},
				]),expression:"[\r\n\t\t\t\t\t'drone_model_id' ,\r\n\t\t\t\t\t{\r\n\t\t\t\t\t\trules: [\r\n\t\t\t\t\t\t\t{ required: true,message: $tt('validate.required','drone.field.drone_model_id') },\r\n\t\t\t\t\t\t],\r\n\t\t\t\t\t},\r\n\t\t\t\t]"}],attrs:{"loading":_vm.droneModelsLoading,"placeholder":_vm.$t('drone.field.drone_model_id.placeholder'),"get-popup-container":_vm.getPopupContainer},on:{"change":_vm.handleModelChange}},_vm._l((_vm.droneModels),function(model){return _c('a-select-option',{key:model.id,attrs:{"value":model.id}},[_vm._v("\r\n\t\t\t\t\t"+_vm._s(model.name)+"\r\n\t\t\t\t")])}),1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.drone_lot_id')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'drone_lot_id' ,
					{
						rules: [
							{ required: true,message: _vm.$tt('validate.required','drone.field.drone_lot_id') },
						],
					},
				]),expression:"[\r\n\t\t\t\t\t'drone_lot_id' ,\r\n\t\t\t\t\t{\r\n\t\t\t\t\t\trules: [\r\n\t\t\t\t\t\t\t{ required: true,message: $tt('validate.required','drone.field.drone_lot_id') },\r\n\t\t\t\t\t\t],\r\n\t\t\t\t\t},\r\n\t\t\t\t]"}],attrs:{"disabled":_vm.droneLotsDisable,"loading":_vm.droneLotsLoading,"placeholder":_vm.$t('drone.field.drone_lot_id.placeholder'),"get-popup-container":_vm.getPopupContainer},on:{"change":_vm.handleLotChange}},_vm._l((_vm.droneLots),function(lot){return _c('a-select-option',{key:lot.id,attrs:{"value":lot.id}},[_vm._v("\r\n\t\t\t\t\t"+_vm._s(lot.lot_name)+"\r\n\t\t\t\t")])}),1)],1),_vm._v(" "),_c('a-form-item',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.selectedModel),expression:"selectedModel"}]},'a-form-item',_vm.wrapperOnlyLayout,false),[_c('DroneLotLiteCard',{attrs:{"bordered":true,"link-target":"_blank","clickable":true,"model":_vm.selectedModel,"lot":_vm.selectedLot}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.serial_no'),"extra":_vm.$t('drone.field.serial_no.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'serial_no' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone.field.serial_no') },
					] },
				]),expression:"[\r\n\t\t\t\t\t'serial_no' ,\r\n\t\t\t\t\t{ rules: [\r\n\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone.field.serial_no') },\r\n\t\t\t\t\t] },\r\n\t\t\t\t]"}],attrs:{"placeholder":_vm.$t('drone.field.serial_no.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.pixhawk_serial_no'),"extra":_vm.$t('drone.field.pixhawk_serial_no.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'pixhawk_serial_no' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone.field.pixhawk_serial_no') },
					] },
				]),expression:"[\r\n\t\t\t\t\t'pixhawk_serial_no' ,\r\n\t\t\t\t\t{ rules: [\r\n\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone.field.pixhawk_serial_no') },\r\n\t\t\t\t\t] },\r\n\t\t\t\t]"}]})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.name'),"extra":_vm.$t('drone.field.name.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'name' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone.field.name') },
					] },
				]),expression:"[\r\n\t\t\t\t\t'name' ,\r\n\t\t\t\t\t{ rules: [\r\n\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone.field.name') },\r\n\t\t\t\t\t] },\r\n\t\t\t\t]"}],staticClass:"drone-input-name",attrs:{"addon-before":_vm.namePrefix,"placeholder":_vm.$t('drone.field.name.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.firmware')}},[_c('FirmwareSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'firmware_id' ,
					{
						rules: [
							{ required: true,message: _vm.$tt('validate.required','drone.field.firmware') },
						],
					},
				]),expression:"[\r\n\t\t\t\t\t'firmware_id' ,\r\n\t\t\t\t\t{\r\n\t\t\t\t\t\trules: [\r\n\t\t\t\t\t\t\t{ required: true,message: $tt('validate.required','drone.field.firmware') },\r\n\t\t\t\t\t\t],\r\n\t\t\t\t\t},\r\n\t\t\t\t]"}],attrs:{"firmware-set-id":_vm.firmwareSetId,"disabled":!_vm.selectedModel}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.latest_flight_time_sec'),"extra":_vm.$t('drone.field.latest_flight_time_sec.help')}},[_c('FlightTimeInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'latest_flight_time_sec' ,
					{
						rules: [
						{ required: true ,message: _vm.$tt('validate.required','drone.field.latest_flight_time_sec') },
					] , initialValue: 0 },
				]),expression:"[\r\n\t\t\t\t\t'latest_flight_time_sec' ,\r\n\t\t\t\t\t{\r\n\t\t\t\t\t\trules: [\r\n\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','drone.field.latest_flight_time_sec') },\r\n\t\t\t\t\t] , initialValue: 0 },\r\n\t\t\t\t]"}]})],1),_vm._v(" "),_c('div',{staticClass:"mysteps-actions"},[_c('a-button',{attrs:{"loading":_vm.formLoading,"type":"info","html-type":"submit","size":"large"}},[_vm._v("\r\n\t\t\t\t "+_vm._s(_vm.$t('common.next'))+" "),_c('a-icon',{attrs:{"type":"right"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }