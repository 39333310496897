<i18n locale="th" lang="yaml" >
drone.create.step2.title : "ระบุข้อมูลอะไหล่ที่ใช้"
drone.create.step2.description : "ระบุ S/N และ ตรวจสอบข้อมูลของอะไหล่ที่ใช้ใน Drone ตัวนี้"

step2.tab.serial_no : "Serial No"
step2.tab.non_serial_no : "ทั่วไป"

drone.field.serial_no.warning.empty : "ควรระบุ Serial No "
drone.field.serial_no.warning.duplicate.self : "Serial No ซ้ำกันเอง"
drone.field.serial_no.warning.duplicate.others : "Serial No ซ้ำกับข้อมูลในระบบ"

step2.confirm_warning.title : "ข้อมูลบางอย่างไม่ถูกต้อง"
step2.confirm_warning.checkbox : "มีการเตือน กรุณาเลือก เพื่อยืนยันว่าจะใช้ช้อมูลตามนี้"
step2.warning.message : "ข้อมูลบางอย่างอาจจะไม่ถูกต้อง ต้องการตรวจสอบก่อน"
step2.warning.confirm.title : "มีข้อมูลบางอย่างที่ไม่ถูกต้อง"
step2.warning.confirm.message : "ข้อมูลที่ส่งเค้ามา มีข้อมูลบางอย่างที่ไม่ถูกต้อง , ยังต้องการสร้าง Drone อยู่หรือไม่ ?"
</i18n>

<template>
<div class="page-padding">
	<div class="step-title">
		{{$t('drone.create.step2.title')}}
	</div>
	<div class="step-description">
		{{$t('drone.create.step2.description')}}
	</div>
	<a-alert v-if="hasWarning"
		class="step2-warning-alert"
		:message="$t('step2.confirm_warning.title')"
		type="warning" show-icon>
		<a-checkbox slot="description" v-model="confirmWarning" >
			{{$t('step2.confirm_warning.checkbox')}}
		</a-checkbox>
	</a-alert>
	<a-tabs
		v-model="activeTab"
	  :tab-position="tabPosition">
		<a-tab-pane
			key="1"
			:tab="$t('step2.tab.serial_no')">
			<div>
				<DronePartsTable
					:show-selectable="true"
					:drone-parts="serialNoParts"
					:show-serial-no="true"
					:editable="true"
					:loading="partsLoading"
					@change="handleSerialNoChange"/>

			</div>
		</a-tab-pane>
		<a-tab-pane
			key="2"
			:tab="$t('step2.tab.non_serial_no')">
			<DronePartsTable
				:drone-parts="nonSerialNoParts"
				:loading="partsLoading"/>
		</a-tab-pane>
	</a-tabs>

	<div class="mysteps-actions">
		<a-button
			size="large"
			@click="handleBackButton">
		<a-icon type="left" /> {{$t('common.back')}}
		</a-button>
		<a-button
			:loading="formLoading"
			type="info"
			html-type="submit"
			size="large"
			@click="handleSubmit">
			{{$t('common.next')}} <a-icon type="right" />
		</a-button>
	</div>
</div>
</template>

<script>
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import DeviceMixin from "@mixins/DeviceMixin.vue"
import DronePartsTable from "@components/drone/DronePartsTable.vue"
import ApiError from '@utils/errors/ApiError'
import { mapGetters } from 'vuex'
import {Tabs,Checkbox,Alert} from "ant-design-vue"
import ModelError from "@utils/errors/ModelError"


export default {
	components : {
		DronePartsTable ,
		"a-tabs" : Tabs, "a-tab-pane" : Tabs.TabPane,
		"a-checkbox" : Checkbox,
		"a-alert" : Alert,
	} ,
	mixins : [HasAntdFormMixin,PageMixin,DeviceMixin] ,
	props : {
		model : {
			type : null,
			default : () => []
		},
		lot : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			activeTab : "1",
			partsLoading : false ,
			droneParts : [],

			serialNoDatas : [],
			hasWarning : false,
			confirmWarning : false,
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		tabPosition() {
			return this.isDesktop ? "left" : "top"
		} ,
		serialNoParts() {
			return this.droneParts.filter((dronePart) => dronePart.has_serial_no)
		} ,
		nonSerialNoParts() {
			return this.droneParts.filter((dronePart) => !dronePart.has_serial_no)
		}
	} ,
	watch : {
		lot : {
			handler(newVal,oldVal) {
				if (newVal.id && newVal.id != oldVal.id) {
					this.fetchDroneParts()
				}
			} ,
			deep : true,
		}
	} ,
	mounted() {
		this.initTabs();
	} ,
	activated() {
		// This is in keep-alive then event activated is hooked
		this.initTabs();
	},
	created() {
		this.fetchDroneParts()
	} ,
	methods : {
		initTabs() {
			this.activeTab = "1";
		} ,
		handleBackButton() {
			this.$emit('back')
		} ,
		fetchDroneParts() {
			if (!this.lot.id) {
				this.droneParts = []
				return
			}
			this.partsLoading = true
			axios.get("/api/drone-lots/"+this.lot.id+"/list-parts-for-drone").then((response) => {
				this.droneParts = response.data.data.drone_new_parts;
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(() => {
				this.partsLoading = false
			})
		} ,
		handleSerialNoChange(value) {
			this.serialNoDatas = value.allData;
			this.hasWarning = false
			this.confirmWarning = false
		} ,
		_manageWarning(warningList,errorList=[]) {
			for(const data of this.serialNoDatas) {
				let warn = null
				for(const warnKey in warningList) {
					if (warnKey == data.key) {
						warn = warningList[warnKey]
						break;
					}
				}
				data.warning = warn || null
				const err = errorList.find((err)=>err.model.key == data.key)
				data.error = err ? ApiError.getStringFieldErrors(err.field_errors) : null
			}
		} ,
		_nextStep() {
			this.$emit('submit',{
				"droneParts" : [...this.droneParts] ,
				"serialNoDatas" : [...this.serialNoDatas] ,
				"hasWarning" : this.hasWarning ,
			})
		} ,
		handleSubmit() {
			// Validate at this step
			this.formLoading = true
			axios.post("/api/drones/part-items-validate",this.serialNoDatas).then((response) => {
				if (response.data.data && response.data.data.has_warning) {
					this._manageWarning(response.data.data.warning_list)

					if (this.confirmWarning) {
						// Continue Processing with Dialog
						this.$confirm({
							title : this.$t('step2.warning.confirm.title') ,
							content : this.$t('step2.warning.confirm.message') ,
							okText : this.$t('common.confirm') ,
							maskClosable : true,
							onOk: ()=> {
								this._nextStep()
							}
						})
					} else {
						this.hasWarning = true
						this.$message.warning(this.$t('step2.warning.message'))
					}
				} else {
					this.hasWarning = false
					this.confirmWarning = false
					// Process without dialog
					this._nextStep()
				}
			}).catch((error)=> {
				if (error instanceof ModelError) {
					const errorResponse = error.errorResponse;
					if (errorResponse.data && errorResponse.data.has_warning) {
						this._manageWarning(errorResponse.data.warning_list,error.model_errors)
					} else {
						this._manageWarning([],error.model_errors)
					}
				}
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(() => {
				this.formLoading = false
			})
		}
	}
}
</script>
<style lang="less" scoped>
	.step2-warning-alert {
		margin-bottom : 24px;
	}
</style>
