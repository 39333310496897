<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone.create.step4.title : "เพิ่ม Drone เรียบร้อย"
drone.create.step4.description : "ทำการเพิ่ม Drone ในระบบเรียบร้อย โดยข้อมูลตามด้านล่าง"

step4.sucess.message : "ทำการเพิ่ม Drone {serial_no} เรียบร้อย"

step4.section.next_action : "ต้องการทำอะไรต่อ ?"

step4.action.create_another : "สร้าง Drone เพิ่มอีก"
step4.action.register : "ลงทะเบียน Drone ตัวนี้"
step4.action.view : "ดูข้อมูลของ Drone ตัวนี้"
step4.action.home : "กลับไปหน้าแรก"
</i18n>

<template>
	<div class="page-padding">
		<div class="step-title">
			{{$t('drone.create.step4.title')}}
		</div>
		<div class="step-description">
			{{$t('drone.create.step4.description')}}
		</div>

		<div class="success-pane">
			<a-icon type="check-circle" class="success-pane-icon"/>
			<div class="success-pane-message">
				{{$t('step4.sucess.message',{ 'serial_no': drone.serial_no })}}
			</div>

			<div class="content-with-details success-pane-content">
				<div class="content-section drone-info-section">
					<div class="content-row">
							<DroneLotLiteCard
								class="drone-lot-card"
								:bordered="true"
								link-target="_blank"
								:clickable="true"
								:model="model"
								:lot="lot"/>
					</div>
					<div class="content-row">
						<div class="content-label">
							{{$t('drone.field.serial_no')}}
						</div>
						<div class="content-value">
							{{drone.serial_no | emptyCheck}}
						</div>
					</div>

					<div class="content-row">
						<div class="content-label">
							{{$t('drone.field.pixhawk_serial_no')}}
						</div>
						<div class="content-value">
							{{drone.pixhawk_serial_no | emptyCheck}}
						</div>
					</div>

					<div class="content-row">
						<div class="content-label">
							{{$t('drone.field.name')}}
						</div>
						<div class="content-value">
							{{drone.name | emptyCheck}}
						</div>
					</div>

					<div class="content-row">
						<div class="content-label">
							{{$t('drone.field.firmware')}}
						</div>
						<div class="content-value">
							{{firmware.name | emptyCheck}}
						</div>
					</div>

					<div class="content-row">
						<div class="content-label">
							{{$t('drone.field.latest_flight_time_sec')}}
						</div>
						<div class="content-value">
							<FlightTimeView :time-seconds="drone.latest_flight_time_sec" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="next-action">
				<div class="next-action-title">
					{{$t('step4.section.next_action')}}
				</div>
				<ul class="next-action-list">
					<li>
						<my-router-link name="drone/view" :param-value="drone.serial_no" param-name="serial_no">
							{{$t('step4.action.view')}}
						</my-router-link>
					</li>
					<li>
						<my-router-link name='drone/register' :param-value="drone.serial_no" param-name="serial_no">
							{{$t('step4.action.register')}}
						</my-router-link>
					</li>
					<li>
						<a @click="createAnother">
							{{$t('step4.action.create_another')}}
						</a>
					</li>
					<li>
						<my-router-link name="dashboard" no-param>
							{{$t('step4.action.home')}}
						</my-router-link>
					</li>
				</ul>
		</div>
	</div>
</template>

<script>
import FlightTimeView from "@components/common/FlightTimeView.vue"
import DroneLotLiteCard from "@components/drone/DroneLotLiteCard.vue"

export default {
	components : {
		FlightTimeView , DroneLotLiteCard
	} ,
	props : {
		model : {
			type : null,
			default : () => []
		},
		lot : {
			type : null,
			default : () => []
		} ,
		firmware : {
			type : null,
			default : () => []
		} ,
		drone : {
			type : null,
			default : () => []
		} ,
	} ,
	methods : {
		createAnother() {
			this.$open({ name: 'admin/drone_create',query: { model: this.model.id,lot: this.lot.id } } ,'_self',true)
		}
	}
}
</script>

<style lang="less" scoped>
.drone-lot-card {
	margin : 0 auto 16px;
}
.success-pane::v-deep {
	border : 2px solid @success-color;
	border-radius : 24px;
	text-align : center;
	padding : 32px 32px 24px;
	max-width: 800px;
	margin : 0 auto 32px;
	.success-pane-icon {
		font-size : 128px;
		color : @success-color;
		margin-bottom: 12px;
	}
	.success-pane-message {
		font-size : 28px;
		font-weight: 600;
		color : @success-color;
		font-family: @font-family-title;
		margin-bottom : 32px;
	}
	.success-pane-content {
		text-align : left;

    margin: auto;
	}


	body[data-device=mobile] & {
		.success-pane-icon {
			font-size : 96px;
			margin-bottom: 16px;
		}
		.success-pane-message {
			font-size : 24px;
			font-weight: 600;
			color : @success-color;
			font-family: @font-family-title;
			margin-bottom : 24px;
		}
		.content-label {
			width : 100px !important;
		}
	}
}
.next-action {
	.next-action-title {
		font-size : 24px;
		font-family: @font-family-title;
		margin-bottom : 8px;
	}
	.next-action-list {
		padding-left : 48px;
		list-style: circle;
		li {
			font-size : 1.1em;
			margin-bottom : 8px;
		}
	}
}
</style>
