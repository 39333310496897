<i18n src="@i18n/drone/drone.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone.create.step3.title : "ตรวจสอบข้อมูล Drone"
drone.create.step3.description : "สรุปข้อมูลในการเพิ่ม Drone ใหม่ในระบบ กรุณาตรวจสอบและยืนยันข้อมูลก่อนนำเข้าระบบ"

step3.section.information : "ข้อมูล Drone"
step3.section.part.serial_no : "อะไหล่ (มี Serial No)"
step3.section.part.others : "อะไหล่ทั่วไป"

step3.confirm.description : "ถ้ามีการยืนยันแล้ว ข้อมูลบางอย่างจะไม่สามารถแก้ไขได้ ต้องติดต่อทาง Admin เพื่อให้ทางระบบแก้ไขอีกที โปรดตรวจสอบข้อมูลให้มั่นใจอีกครั้ง"
</i18n>

<template>
	<div class="page-padding">
		<div class="step-title">
			{{$t('drone.create.step3.title')}}
		</div>
		<div class="step-description">
			{{$t('drone.create.step3.description')}}
		</div>

		<div class="content-with-details">
			<div class="content-section drone-info-section">
				<div class="content-header">
					{{$t('step3.section.information')}}
				</div>
				<div class="content-row lot-row">
						<div class="content-label">
						{{$t('drone.field.drone_lot_id')}}
					</div>
					<div class="content-value">
						<DroneLotLiteCard
							:bordered="true"
							link-target="_blank"
							:clickable="true"
							:model="model"
							:lot="lot"/>
					</div>
				</div>
				<div class="content-row">
					<div class="content-label">
						{{$t('drone.field.serial_no')}}
					</div>
					<div class="content-value">
						{{createFormData.serial_no | emptyCheck}}
					</div>
				</div>

				<div class="content-row">
					<div class="content-label">
						{{$t('drone.field.pixhawk_serial_no')}}
					</div>
					<div class="content-value">
						{{createFormData.pixhawk_serial_no | emptyCheck}}
					</div>
				</div>

				<div class="content-row">
					<div class="content-label">
						{{$t('drone.field.name')}}
					</div>
					<div class="content-value">
						{{createFormData.name | emptyCheck}}
					</div>
				</div>

				<div class="content-row">
					<div class="content-label">
						{{$t('drone.field.firmware')}}
					</div>
					<div class="content-value">
						{{firmware.name | emptyCheck}}
					</div>
				</div>
				<div class="content-row">
					<div class="content-label">
						{{$t('drone.field.latest_flight_time_sec')}}
					</div>
					<div class="content-value">
						<FlightTimeView :time-seconds="createFormData.latest_flight_time_sec" />
					</div>
				</div>
			</div>

			<div class="content-section">
				<div class="content-header">
					{{$t('step3.section.part.serial_no')}}
				</div>
				<DronePartsTable
					:drone-parts="serialNoDatas"
					:show-serial-no="true"/>
			</div>


			<div class="content-section">
				<div class="content-header">
					{{$t('step3.section.part.others')}}
				</div>
				<DronePartsTable
					:drone-parts="nonSerialNoParts"/>
			</div>
		</div>
		<div class="step3-confirm-display">
			{{$t('step3.confirm.description')}}
		</div>

		<div class="mysteps-actions">
			<a-button
				size="large"
				:disabled="isSubmitting"
				@click="handleBackButton">
			<a-icon type="left" /> {{$t('common.back')}}
			</a-button>
			<a-button
				type="info"
				html-type="submit"
				size="large"
				:loading="isSubmitting"
				@click="handleSubmit">
				{{$t('common.confirm')}}
			</a-button>
		</div>
	</div>
</template>

<script>
import DroneLotLiteCard from "@components/drone/DroneLotLiteCard.vue"
import FlightTimeView from "@components/common/FlightTimeView.vue"
import DronePartsTable from "@components/drone/DronePartsTable.vue"
import axios from "axios"
import ApiError from '@utils/errors/ApiError'
export default {
	components : {
		DroneLotLiteCard , FlightTimeView ,
		DronePartsTable,
	} ,
	props : {
		model : {
			type : null,
			default : () => []
		},
		lot : {
			type : null,
			default : () => []
		} ,
		firmware : {
			type : null,
			default : () => []
		} ,
		createFormData : {
			type : Object,
			default : () => []
		} ,
		droneParts : {
			type : Array,
			default : () => []
		} ,
		serialNoDatas : {
			type : Array ,
			default : () => []
		} ,
	} ,
	data(){
		return {
			isSubmitting : false,
		}
	} ,
	computed: {
		nonSerialNoParts() {
			return this.droneParts.filter((dronePart) => !dronePart.has_serial_no)
		}
	},
	methods : {
		handleBackButton() {
			this.$emit('back')
		} ,
		handleSubmit() {
			this.isSubmitting = true
			const formData = {
				drone : this.createFormData ,
				serial_no_list : this.serialNoDatas
			}
			axios.post("/api/drones/create",formData).then((response)=>{
				this.$emit("submit",response.data.data)
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.isSubmitting = false
			})
		} ,
	}
}
</script>

<style lang="less" scoped>
@media(max-width: @screen-md-max) {
	.content-row.lot-row {
		.content-label {
			display : none;
		}
		.content-value {
			width : 100%;
			margin : 0 auto 12px;
			float : none;
			.drone-lot-lite-card {
				margin : 0 auto;
			}
		}
	}
}
.step3-confirm-display {
	text-align : right;
	margin-top : 32px;
}
.content-section {
	margin-bottom : 24px;
}
.drone-info-section {
	margin : 0 auto 32px;
	max-width : 1024px;
	padding : 24px 24px;
	border : 1px solid @border-color-base;
	border-radius: @border-radius-base;

}
</style>
