<template>
	<span :class="cssClasses">
		<a-input
			v-model="internalValue"
			:allow-clear="inputAllowClear"
			v-bind="$attrs">
			<MyTooltip v-show="tooltipProps.show"
				slot="suffix"
				:title="tooltipProps.title"
				:display-mode="tooltipProps.displayMode">
				<a-icon :type="tooltipProps.icon"
					class="ant-form-item-children-icon"/>
			</MyTooltip>
		</a-input>
	</span>
</template>

<script>
import debounce from "lodash/debounce"
import { isStringEmpty } from '@utils/stringUtil'
import MyTooltip from "@components/common/MyTooltip.vue"
export default {
	components : {
		MyTooltip,
	} ,
	model: {
    prop: 'value',
    event: 'change.value'
  },
	props : {
		value : {
			type: null,
			default : ''
		},
		dataKey : {
			type : String,
			default : '',
		} ,
		loading : {
			type : Boolean,
			default: false
		} ,
		error : {
			type : String,
			default : null
		} ,
		warning : {
			type : String,
			default : null
		} ,
		allowClear : {
			type : Boolean,
			default : false,
		} ,
	}	,
	data() {
		return {
			internalValue : this.value,
		}
	},
	computed : {
		inputAllowClear() {
			return this.allowClear// && !isStringEmpty(this.error) && !isStringEmpty(this.warning)
		} ,
		cssClasses() {
			const css = ['myinput-feedback','has-feedback']
			if (!isStringEmpty(this.error)) {
				css.push('has-error')
			} else if (!isStringEmpty(this.warning)) {
				css.push('has-warning')
			}
			return css;
		} ,
		tooltipProps() {
			const tooltipProps = {
				show : false,
				title : undefined,
				icon : "minus" ,
				displayMode : undefined,
			}
			if (this.loading) {
				tooltipProps.show = true
				tooltipProps.title = this.$t("common.loading")
				tooltipProps.icon = "loading"
			} else if (!isStringEmpty(this.error)) {
				tooltipProps.show = true
				tooltipProps.title = this.error
				tooltipProps.icon = "close-circle"
				tooltipProps.displayMode = 'error'
			} else if (!isStringEmpty(this.warning)) {
				tooltipProps.show = true
				tooltipProps.title = this.warning
				tooltipProps.icon = "exclamation-circle"
				tooltipProps.displayMode = 'warning'
			}
			return tooltipProps
		}
	} ,
	watch : {
		value(newVal) {
			if (newVal == this.internalValue)
				return
			this.internalValue = newVal

		} ,
		internalValue(newVal,oldVal) {
			if (newVal === oldVal)
				return;
			this.debounceTextChange()
		}
	} ,
	created() {
		this.debounceTextChange = debounce(this.handleTextChange,300);
	} ,
	methods : {
		handleTextChange() {
			this.$emit('change.value',this.internalValue)
			this.$emit('change',{
				value : this.internalValue,
				key : this.dataKey
			})
		} ,

	}
}
</script>

<style lang="less" scoped>
.has-feedback.myinput-feedback::v-deep {
	.ant-form-item-children-icon {
		pointer-events: initial;
	}
}
.myinput-feedback::v-deep {
	.ant-input-suffix > .ant-input-clear-icon {
		z-index : 10;
		left : -5px;
		position :absolute;
	}
}
</style>
