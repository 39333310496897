import { render, staticRenderFns } from "./DroneCreateStep3.vue?vue&type=template&id=492dffc3&scoped=true"
import script from "./DroneCreateStep3.vue?vue&type=script&lang=js"
export * from "./DroneCreateStep3.vue?vue&type=script&lang=js"
import style0 from "./DroneCreateStep3.vue?vue&type=style&index=0&id=492dffc3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492dffc3",
  null
  
)

/* custom blocks */
import block0 from "@i18n/drone/drone.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fdrone%2FDroneCreateStep3.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./DroneCreateStep3.vue?vue&type=custom&index=1&blockType=i18n&locale=th&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports