<i18n locale="th" lang="yaml" >
part.field.sku : "SKU"
part.field.part_type : "ประเภท"
drone_part.field.part_index : "ชิ้นส่วนที่"
drone_part.field.part_index.display : "ชิ้นที่ {value}"
drone_part.field.remark : "หมายเหตุ"
drone_part.field.serial_no : "S/N"
drone_part.field.type : "การติดตั้ง"
</i18n>

<template>
	<a-table
		:loading="loading"
		bordered class="mytable drone-part-table"
		:row-key="record => record.key"
		:data-source="displayMutableDroneParts"
		:pagination="false"
		size="small">
		<a-table-column v-if="showSelectable && editable" class="column-checkbox">
			<template slot-scope="record">
				<a-checkbox v-model="record.is_create"
						@change="handleCreatable" />
			</template>
 		</a-table-column>
		<a-table-column
			:sorter="tableSortSKU"
			:title="$t('part.field.sku')" data-index='part.sku'
			:width="150">
			<template slot-scope="text,record">
				<my-router-link name="part/view" :param-value="record.part_id" auth-action="read" auth-module="part"
					new-window>
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('common.table.column.detail')" data-index="part.name"
			:sorter="tableSortName">
			<template slot-scope="text,record">
				<MyPopover overlay-class-name="mycard-popover" :destroy-tooltip-on-hide="true">
					<template slot="content">
						<PartLiteCard :part="record.part" size="small"  link-target="_blank" />
					</template>
					{{record.part.name}}
				</MyPopover>
			</template>
		</a-table-column>

		<a-table-column
			:title="$t('drone_part.field.type')" data-index='type'
			:width="100"
			:sorter="tableSortDronePartType"
			default-sort-order="ascend">
			<template slot-scope="text">
				{{$tenum('drone_part_type',text)}}
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('part.field.part_type')" data-index='part.part_type'
			:width="120"
			:sorter="tableSortPartType">
			<template slot-scope="text">
				{{$tenum('part_type',text)}}
			</template>
		</a-table-column>

		<a-table-column
			:title="$t('drone_part.field.part_index')" data-index='part_index'
			:width="120"
			align="center">
			<template slot-scope="partIndex">
				<span v-if="partIndex > 0">
					{{$t('drone_part.field.part_index.display',{ value: partIndex })}}
				</span>
				<span v-else>
					{{$t('common.unspecify')}}
				</span>
			</template>
		</a-table-column>

		<a-table-column
			v-if="showSerialNo"
			:title="$t('drone_part.field.serial_no')"
			:width="200">
			<template slot-scope="record">
				<InputWithFeedback
					v-if="editable"
					v-model="record.serial_no"
					:disabled="!record.is_create"
					:data-key="record.key"
					:error="record.error"
					:warning="record.warning"
					@change="handleSerialNoChange"/>
				<span v-else>
					<DataWithFeedback
						:content="record.serial_no"
						:error="record.error"
						:warning="record.warning"/>
				</span>
			</template>
		</a-table-column>

		<a-table-column
			:title="$t('drone_part.field.remark')"
			data-index="remark"
			:width="50"
			align="center">
			<template slot-scope="remark">
				<MyTooltip v-if="$notEmpty(remark)"
					display-mode="white"
					:destroy-tooltip-on-hide="true">
					<div slot="title" v-trim class="multiline">
						{{remark}}
					</div>
					<a-icon type="message" class="cursor-pointer"/>
				</MyTooltip>
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import Vue from "vue"
import MyPopover from "@components/common/MyPopover.vue"
import {Table,Checkbox} from "ant-design-vue"
import PartLiteCard from "@components/part/PartLiteCard.vue"
import SortDronePartMixin from "@mixins/drone/SortDronePartMixin.vue"
import InputWithFeedback from "@components/input/InputWithFeedback.vue"
import DataWithFeedback from "@components/common/DataWithFeedback.vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import { mapGetters } from 'vuex'


export default {
	components : {
		PartLiteCard , InputWithFeedback, DataWithFeedback,
		MyPopover ,
		"a-table" : Table, "a-table-column" : Table.Column , "a-checkbox" : Checkbox,
		MyTooltip ,
	} ,
	mixins : [SortDronePartMixin] ,
	props : {
		loading : {
			type : Boolean,
			default : false
		} ,
		droneParts : {
			type : null,
			default : () => []
		} ,
		showSelectable : {
			type : Boolean,
			default : false,
		} ,
		editable : {
			type : Boolean,
			default : false,
		} ,
		showSerialNo : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			mutableDroneParts : []
		}
	} ,
	computed :{
		...mapGetters('drone',['getPartById']) ,
		displayMutableDroneParts() {
			if (this.editable) {
				// show all
				return this.mutableDroneParts
			} else {
				return this.mutableDroneParts.filter(i => i.is_create)
			}
		}
	} ,
	watch : {
		droneParts() {
			this.refreshMutableDroneParts();
		} ,
	} ,
	created() {
		this.refreshMutableDroneParts();
	} ,
	methods : {
		refreshMutableDroneParts() {
			this.mutableDroneParts = [];
			for(const dronePart of this.droneParts) {
				const data = Object.assign({},dronePart);
				if (!data.part)
					Vue.set(data,'part',this.getPartById(dronePart.part_id))
				if (this.editable) {
					Vue.set(data,'warning',null)
					Vue.set(data,'error',null)
				}
				this.mutableDroneParts.push(data)
			}
			this.handleSerialNoChange()
		} ,
		handleSerialNoChange(payload=null) {
			let key = null
			if (payload) {
				key = payload.key
				// clear error, change
				const changed = this.mutableDroneParts.find((part) => part.key == key)
				if (changed) {
					changed.warning = null
					changed.error = null
				}
			}

			this.$emit('change',{
				changeKey : key,
				allData : this.mutableDroneParts
			})
		} ,
		handleCreatable() {

		}
	}

}
</script>
